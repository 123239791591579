import { ref, watch, computed } from 'vue';


export function useBatchedCompletion(options) {
    const {
        query,
        delay = 500,
        fetchResults = (url, signal) => {
            return fetch(url, { signal })
                .then(resp => resp.json())
                .then(json => json.result);
        },
        defaultValue = [],
    } = options;

    const completions = ref(defaultValue);
    const searching = ref(false);

    let completionDelayTimeout    = null;
    let completionPendingUrl      = null;
    let completionFetchedUrl      = null;
    let completionRequest         = null;
    let completionAbortController = null;

    function setCompletions(url, result) {
        searching.value = false;
        completionFetchedUrl = url;
        completions.value = result;
    }

    function cancelBatch() {
        if (completionDelayTimeout) {
            clearTimeout(completionDelayTimeout);
            completionDelayTimeout = null;
        }
    }

    function requestCompletionsUpdate() {
        const url = query.value;
        if (url === null || url === completionFetchedUrl) {
            cancelBatch();
            updateCompletions();
        } else {
            const startOfBatch = !completionDelayTimeout;
            if (startOfBatch) {
                searching.value = true; // We have not performed a request yet, but update the UI like we have
                completionDelayTimeout = setTimeout(() => {
                    completionDelayTimeout = null;
                    updateCompletions();
                }, delay);
            }
        }
    }

    function cancelPendingRequest() {
        if (completionAbortController) {
            completionAbortController.abort();
            completionAbortController = null;
        }
        completionPendingUrl = null;
        completionRequest = null;
    }

    function updateCompletions() {
        const url = query.value;
        if (url === null) {
            cancelPendingRequest();
            setCompletions(url, defaultValue);
            return;
        } else if (url === completionFetchedUrl) {
            cancelPendingRequest();
            setCompletions(url, completions.value);
            return;
        } else if (url === completionPendingUrl) {
            return;
        } else {
            cancelPendingRequest();
        }

        searching.value = true;
        completionPendingUrl = url;
        completionAbortController = new AbortController();
        const signal = completionAbortController.signal;
        const req = completionRequest = fetchResults(url, signal)
            .catch(error => {
                if (!(error instanceof DOMException && error.name === 'AbortError')) {
                    console.error('Error when fetching suggestions', error);
                }
                return defaultValue;
            })
            .then(result => {
                if (completionRequest === req) {
                    completionRequest = null;
                    completionAbortController = null;
                    completionPendingUrl = null;
                    setCompletions(url, result);
                }
            });
    }

    return { requestCompletionsUpdate, completions, searching };
}

export function useCompletionSelection(completions) {
    const innerSelection = ref(-1);
    const selection = computed({
        get() {
            return innerSelection.value;
        },
        set(value) {
            if (value >= completions.value.length) {
                value = completions.value.length - 1;
            }
            if (value < -1) {
                value = -1;
            }
            innerSelection.value = value;
        }
    });

    watch(completions, () => innerSelection.value = -1, { flush: 'sync' });

    function selectNext() {
        innerSelection.value++;
    }

    function selectPrev() {
        innerSelection.value--;
    }

    return { selection, selectNext, selectPrev };
}
